import React, {Component} from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

export default class angebotDetailsPage extends Component {

    componentDidMount() {
        if (typeof (window) !== "undefined") {
            let markdown = document.getElementById('markdown-offer');
            let links = Array.from(markdown.getElementsByTagName('a'));
            links.forEach(link => {
                link.target = "_blank";
            });
            let image = Array.from(markdown.getElementsByTagName('img'));
            image.forEach(images => images.title = images.alt);

        }
    }

    render() {
        let siteType = process.env.GATSBY_API_URL;
        let offerdata = this.props.data.strapiOfferPages;
        let referenc = [];
        let refdat;
        let offersData = this.props.data.allStrapiOfferPages.edges;

        offerdata.references.sort((a, b) => a.order - b.order).forEach(references => {
            this.props.data.allStrapiReferences.edges.forEach(edges => {
                if (edges.node.url === references.url) {
                    refdat = edges.node;
                }
            });
            referenc.push(refdat)
        });

        return (
            <Layout page="angebot" angebotType={this.props.data.strapiOfferPages.url}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{this.props.data.strapiOfferPages.title} | Paixon</title>
                    <meta name="description" content={this.props.data.strapiOfferPages.meta_description}/>
                </Helmet>
                <Header className={`offer/${offerdata.url}`} slogan={offerdata.slogan}
                        blogphoto={{backgroundImage: `url(${this.props.data.strapiOfferPages.slogan_image.publicURL})`}}
                        offersData={offersData}/>
                <main className="container pt offer offer-details">
                    <div className={`section-white content ${offerdata.url}`}>
                        <div id="markdown-offer">
                            <ReactMarkdown children={offerdata.content} className={`markdown`}/>
                        </div>
                    </div>

                    {(offerdata.references.length !== 0) && (referenc.length >= 1) && (
                        <div className="row logos our-projects">
                            <h2>Beispiele von erfolgreichen Projekten</h2>
                            {referenc.map(edge => {
                                const reference = edge;
                                return (
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <a
                                            className={`reference`}
                                            style={{backgroundImage: `url(${reference.companyImage.publicURL})`}}
                                            href={`/referenzen/${reference.url}`}
                                            title={reference.title}
                                        >
                                            {" "}
                                            {(reference.filmes.length > 0) && (
                                                <div className='reference-logos-movie'>
                                                    <div
                                                        className="reference-logos-movie-icon"></div>
                                                </div>
                                            )}
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    <div className='section-white offer-call-to-action'>
                        <ReactMarkdown children={offerdata.contact_callToAction_section}/>
                        <div className="offer-call-to-action-button action-button-element">
                            <a className="action-button"
                               href="mailto:info@paixon.ch">
                                <h3 className="action-button-text">{offerdata.contact_callToAction_button}</h3>
                            </a>
                        </div>
                    </div>

                </main>
            </Layout>
        )
    }
}


export const pageQuery = graphql`
query OffersDetails($offerUrl: String!) {
    strapiOfferPages(url: { eq: $offerUrl }) {
        content
        section_color
        title
        meta_description
        url
        slogan
        references {
            url
            title
            order
        }
        slogan_image {
            publicURL
        }
        contact_callToAction_section
        contact_callToAction_button
    }
  
    allStrapiReferences {
        edges {
            node {
                id
                url
                title
                type
                status
                filmes {
                    title
                    url
                }
                companyImage {
                    publicURL
                }
            }
        }
    }
    
    allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
}`
